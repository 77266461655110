import React from 'react';
import axios from 'axios';
import authHeader from '../components/services/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getLoginStatus } from '../components/services/auth-header';
import { Link } from "react-router-dom";
// import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
// import { format, parseISO } from 'date-fns'

// const TestResults = () => {
// return (
// 	<div>
// 	<h1>Wyniki testów</h1>
// 	</div>
// );
// };

class TestResults extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			json_data: {},
		}
}
	componentDidMount() {
		if (getLoginStatus()) {
		 axios.get(process.env.REACT_APP_BACKEND + "/userhistory", {headers: authHeader()})
		 .then((response) => {
			this.setState({json_data:response.data})
			//console.log(this.state.json_data)
		 })
		}
	}
	render() {
		if (getLoginStatus()) {
		return (
			<div className="container">
				<table className="table">
				<thead>
					<tr>
					<th scope="col">ID</th>
					<th scope="col">Data wykonania</th>
					<th scope="col">Ilość błedów</th>
					</tr>
				</thead>
				<tbody>
							{Object.keys(this.state.json_data).map((v) => (
								<tr key={this.state.json_data[v].tid}>
								<td><Link to={{ pathname: `/showtest/${this.state.json_data[v].tid}`}}>{this.state.json_data[v].tid}</Link></td>
								<td>{this.state.json_data[v].ts}</td>
								<td>{this.state.json_data[v].bc}</td>
								</tr>
								
							))}
				</tbody>
				</table>
			</div>
		)
	}  else {
		return  (
			<h5>Nie jesteś zalogowany</h5>
		)
	}
}
}
export default TestResults;
