import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoginStatus } from '../components/services/auth-header';

const Logout = () => {
	const navigate = useNavigate();
	localStorage.removeItem("user")
	localStorage.removeItem("visit")
	//this.setState(this.baseState)
	React.useEffect(() => {
        if (!getLoginStatus()) {
          navigate('/');
        }
      });
	return (
	<div>
	<h1>Wylogowano ..</h1>
	</div>
	)
	
};

export default Logout;
