import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TestRunItem from '../components/TestRun';
import { FcCancel, FcOk } from "react-icons/fc";
import axios from 'axios';
import authHeader from '../components/services/auth-header';
import { getLoginStatus } from '../components/services/auth-header';
import { checkUUID, shuffle } from '../components/services/helpers';


class RunTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          questions: [],
          answers: 0,
          selectedAnswers: {},
          isActive: false,
          testrun_id: "",
          results: {},
          isLoggedIn: false,
          errorMessage: "Nie jesteś zalogowany",
          resultMessage: <button type = "button" className = "btn btn-default">WYNIK</button>,
          resultText: "",
          newTestVisible: false,
          canEdit: true,
          selectedColor: "#3069b0",
          defaultStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#F4F1AA"
          },
          selectedStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#3069b0"
          },
        }
        const visit = localStorage.getItem("visit");
        let tmpSelectedAnswers = { ...this.state.selectedAnswers}
        if (checkUUID(visit)) {
          const testRequestURL = process.env.REACT_APP_BACKEND + "/generate_test/"  + visit;
          axios.post(testRequestURL,{}, { headers: authHeader()})
          .then(response => {
            let data = {}
            console.log(response.data);
            try {
              data = JSON.parse(response.data);
            } catch {
              data = response.data;
            }
            //console.log(data);
            if (data.error) {
              this.setState({errorMessage:data.error})
            } else {
            data.map(v => {
              shuffle(v.question.answers)
              //console.log(v.question.answers)
              
            });
            //console.log(data)
            this.setState({questions:data})
            this.setState({testrun_id:data[0].testrun_id})
            this.setState({isActive: true})
            let new_results = {}
            data.map(v => {
              v.question.answers.map(q => {
                  let t_style =+ v.id + "_" + v.testrun_id + "_" + q.id;
                  tmpSelectedAnswers[t_style] = this.state.defaultStyle;
                  let eid="answer_" + q.id;
                  new_results[eid] = ""
              })
            })
            this.setState({results:new_results})
            this.setState({selectedAnswers:tmpSelectedAnswers})
            //console.log(new_results)
          }})
          .catch((error)=>{
            console.log(error);
          });
          this.answerSelect = this.answerSelect.bind(this)
          this.checkTestResults = this.checkTestResults.bind(this)
          this.newTest = this.newTest.bind(this)
      }
        // axios.post("http://10.3.4.21:5000/generate_test/edd59bf8-8bbe-11ed-b7c4-49bf9c5d5141",{}, { headers: authHeader()})
        // .then(response => {
        //     let data = response.data;
        //     console.log(data)
        //     this.setState({questions:response.data})

        //   this.setState({testrun_id:data[0].testrun_id})
        //   let new_results = {}
        //   data.map(v => {
        //     v.question.answers.map(q => {
        //         //let eid="answer_" + v.id + "_" + "_" + v.testrun_id + "_" + q.id;
        //         let eid="answer_" + q.id;
        //         new_results[eid] = ""
        //     })
        //   })
        //   this.setState({results:new_results})
        // })
        // .catch((error)=>{
        //     console.log(error);
        //  });
        // this.answerSelect = this.answerSelect.bind(this)
        // this.checkTestResults = this.checkTestResults.bind(this)
      }
    

      newTest = (e) => {
        //console.log(this.state.answers)
        var wrongAnswerCounter = 0;
        const tid = e.currentTarget.id;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          };
          const testgenurl = process.env.REACT_APP_BACKEND + "/testresults/" + tid
          fetch(testgenurl, requestOptions)
          .then(response => response.json())
          .then((data) => {
            if (data.length < 10) {
                let missing_answers = 10 - data.length;
                alert("Nie zakończyłeś aktualnego testu. Brakuje " + missing_answers + " odpowiedzi.")
            } else {
              window.location.reload();
            }
          })
      }

      answerSelect = (e)  => {
        if (this.state.canEdit) {
        //let elemid = this.props.a1.id
        const id = e.currentTarget.id;
        //console.log("answerSelect() targetId: " + id);
        const aid = e.currentTarget.id.split("_")
        let color_id = aid[0] + "_" + aid[1]  + "_" + aid[2]
        let tmpSelectedAnswers = { ...this.state.selectedAnswers}
        tmpSelectedAnswers[color_id] = false;
        let current_keys = Object.keys(tmpSelectedAnswers)
        //console.log("Keys before clearup:",current_keys);
        for (const k of current_keys) {
          //console.log("Checking if key " + k + " need to be zeroed")
          const workQuestion = aid[0] + "_" + aid[1];
          if (k.startsWith(workQuestion)) {

              tmpSelectedAnswers[k] = this.state.defaultStyle
          }
        }
        tmpSelectedAnswers[color_id] = this.state.selectedStyle
        //console.log(tmpSelectedAnswers)
        this.setState({selectedAnswers: tmpSelectedAnswers});

        const testgenurl = process.env.REACT_APP_BACKEND + "/commit_answer/" + aid[0] + "/" + aid[1] + "/" +  aid[2];
 
        axios.post(testgenurl,{}, {headers: authHeader()})
        .then(response => {

        })
        .catch(function(erorr) {
             console.log("Error:")
             console.log(console.error())
           })
      }
    }
      checkTestResults = (e) => {
        var wrongAnswerCounter = 0;
        const tid = e.currentTarget.id;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          };
          const testgenurl = process.env.REACT_APP_BACKEND + "/testresults/" + tid
          fetch(testgenurl, requestOptions)
          .then(response => response.json())
          .then((data) => {
            if (data.length < 10) {
                let missing_answers = 10 - data.length;
                alert("Nie odpowiedziałeś na wszytkie pytania. Brakuje " + missing_answers + " odpowiedzi.")
            } else {
                let new_state = {}
                //console.log(data)
                data.map ( r => {
                    r.answers.map(n => {
                        let answer_id = "answer_" + n;
                        new_state[answer_id] = <FcCancel size={28}/>
                    })
                    let correct_answer = "answer_" + r.correct_answer.id;
                    new_state[correct_answer] = <FcOk size={28}/>
                    let answer_id = "answer_" + r.answer.id;
                    if (r.answer.is_correct) {
                        new_state[answer_id] = <FcOk size={28}/>
                        //element.innerHTML.className = {FcOk}
                    } else {
                        wrongAnswerCounter++;
                        new_state[answer_id] = <FcCancel />
                    }
                    //console.log(answer_id)
                    this.setState({newTestVisible: true})
                })
                // wywalamy wynik
                this.setState({results:new_state})
                //console.log("wrongAnswerCounter: ", wrongAnswerCounter)
                if (wrongAnswerCounter > 1) {
                  this.setState({resultMessage: <button type = "button" className = "btn btn-danger">NIE ZDANY</button>})
                  var result =  "Ilość błędnych odpowiedzi: "  + wrongAnswerCounter;
                  this.setState({resultText: result})
                  this.setState({canEdit: false})
                } else {
                  this.setState({resultMessage: <button type = "button" className = "btn btn-success">ZDANY</button>})
                }
            }
            console.log(data)
          })
          .catch(function(erorr) {
            console.log("Error:")
            console.log(console.error())
          })
      }
    render() {
      // const visit = localStorage.getItem("visit");
      // if (checkUUID(visit)) {
      //   const testRequestURL = "http://10.3.4.21:5000/generate_test/"  + visit;
      //         axios.post(testRequestURL,{}, { headers: authHeader()})
      // .then(response => {
      //     let data = response.data;
      //     console.log(data)
      //     this.setState({questions:response.data})

      //   this.setState({testrun_id:data[0].testrun_id})
      //   let new_results = {}
      //   data.map(v => {
      //     v.question.answers.map(q => {
      //         //let eid="answer_" + v.id + "_" + "_" + v.testrun_id + "_" + q.id;
      //         let eid="answer_" + q.id;
      //         new_results[eid] = ""
      //     })
      //   })
      //   this.setState({results:new_results})
      // })
      // .catch((error)=>{
      //     console.log(error);
      //  });
      // this.answerSelect = this.answerSelect.bind(this)
      // this.checkTestResults = this.checkTestResults.bind(this)
      // }
 
      if (getLoginStatus() && this.state.isActive) {
return (
        <div className="container">
        {/* <div className='row'><ShowNavbar name="PATENTD" /></div> */}
        <div className="row">
        <div className='App list-group-item
        justify-content-center align-items-center mx-auto' 
        style={{ "marginTop":"15px", "backgroundColor": "#e3f2fd"}}>
          <h1>
            Test egzaminacyjny na Patent Strzelecki
            </h1>
            <h6>Wynik testu zostanie zaprezentowany po odpowiedzi na wszystkie pytania</h6>

          </div>
          </div>
          {this.state.questions.map((d,index) => ( 
            <TestRunItem testrun_id={d.testrun_id} id={d.id} question={d.question} index={index} key={d.id} state={this.state} qid={d.qid} answerSelect={this.answerSelect} />
            ))}
          <div className="row border my-2">
          <div className='row'><div className='col'></div><div className='col my-2'>{this.state.resultMessage} {this.state.resultText}</div></div>
          </div>
          <div className='row'>
          <div className='col-sm-1'></div>
            <div className='col d-flex'>
            <button className="btn btn-dark text-white" id={this.state.testrun_id} onClick={this.newTest}
                  style={{'borderRadius': '10px', "fontWeight":"bold", "display":  this.state.newTestVisible ? "block" : "none" }}>
                    Nowy Test
                </button>
              </div>

              <div className='col d-flex'>
              <button className="btn btn-dark text-white" id={this.state.testrun_id} onClick={this.checkTestResults}
                  style={{'borderRadius': '10px', "fontWeight":"bold"}}>
                    Zakończ test
                </button>
              </div>

                </div>
          <div className='row my-2'>
            <div className='col'>
            <h6 className="card">Copyright(c) 2023, PZSSPATENT</h6></div>
          </div>

      </div>
);
          } else {
            return (
              <div className="my-2"><h5>{this.state.errorMessage}</h5></div>
            )
          }
};
}

export default RunTest;
