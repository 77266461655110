// import { toHaveStyle } from "@testing-library/jest-dom/dist/matchers";
import React from "react";
import axios from 'axios';
import { useState } from "react";

import { useNavigate } from 'react-router-dom';
import {useLocation} from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    // any other hooks can be added and passed on
    return (
      <Component
        navigate={navigate}
        {...props}
        />
    );
  };
  
  return Wrapper;
};

// class Authenticate extends React.Component {
// 	queryParams = new URLSearchParams(window.location.search)
//     token = this.queryParams.get("token")
//     //access_token = ""
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLoggedIn: false
//         }
//         axios
//         .get("http://10.3.4.21:5000/authorize?token=" + this.token, {
//             responseType: "json",
//         })
//         .then(function (response) {
//             //console.log(response.data)
//             if (response.data.access_token) {
//                 console.log(response.data.access_token)
//                 localStorage.setItem("user", JSON.stringify(response.data));
//                 this.setState({isLoggedIn: true})
//                 this.props.history.push("/")
//                 //redirect("/");
//                 //navigate("/")
//               }

//         });
//     }
// 	render() {
// 		return (
//         <div>
//             {this.token}
//         </div>
// 		)
// 	};
// };
const Authenticate = () => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
        axios
        .get(process.env.REACT_APP_BACKEND + "/authorize/?token=" + token, {
            responseType: "json",
        })
        .then(function (response) {
            //console.log(response.data)
            if (response.data.access_token) {
                //console.log(response.data.access_token)
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("visit", token);
                setisLoggedIn(true);
                //this.setState({isLoggedIn: true})
                //this.props.history.push("/")
                //return redirect("/")
              }
              let data = {};
              try {
                data = JSON.parse(response.data)
              } catch {
                data = response.data
              }
              if (data.error) {
                console.log("Authenticate() error: " + data.error)
                setErrorMessage(data.error);
              }

        });
    //}
    React.useEffect(() => {
        if (isLoggedIn) {
          navigate('/');
        }
      });
    
    return (
      <div className="row">
          <div className="my-2"><h5>{errorMessage}</h5></div>
      </div>
    );
    };

export default Authenticate;
