import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TestRunItem from '../components/TestRun';
import { FcCancel, FcOk } from "react-icons/fc";
import axios from 'axios';
import authHeader from '../components/services/auth-header';
import { getLoginStatus } from '../components/services/auth-header';
import { checkUUID, shuffle } from '../components/services/helpers';
import {useLocation} from "react-router-dom";
import withRouter from '../components/withRouter';

class ShowTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          questions: [],
          results: {},
          testrun_id:"",
          errorMessage: "Nie jesteś zalogowany",
          selectedAnswers: {},
          defaultStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#F4F1AA"
          },
          selectedStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#8194a0"
          },
          correctStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#039643"
          },
          incorrectStyle: {
            'borderRadius': '10px',
            "fontWeight":"bold",
            "width": "100%",
            "backgroundColor": "#d05169"
          },
        }
      }
      componentDidMount() {
        const visit = localStorage.getItem("visit");
        let tmpSelectedAnswers = { ...this.state.selectedAnswers}
        if (checkUUID(visit)) {
          console.log(this.props.params.tid);
          const testRequestURL = process.env.REACT_APP_BACKEND + "/testrun_results/"  + this.props.params.tid;
            axios.get(testRequestURL,{}, { headers: authHeader()})
            .then(response => {
              let data = response.data;
              //console.log(data);
              data.map(v => {
                shuffle(v.question.answers)
              });
              console.log(data)
              this.setState({questions:data})
              this.setState({testrun_id:data[0].testrun_id})
              let new_results = {}
              let new_state = {}
              data.map(v => {
                //console.log(answer_id)
                v.question.answers.map(q => {
                  let t_style =+ v.id + "_" + v.testrun_id + "_" + q.id;
                  tmpSelectedAnswers[t_style] = this.state.defaultStyle;
                  let eid="answer_" + q.id;
                  new_results[eid] = <FcCancel size={28}/>
                })
                let answer_id = v.id + "_" + v.testrun_id + "_" + v.answer_id;
                if (v.answer_id != v.correct_answer.id) {
                  tmpSelectedAnswers[answer_id] = this.state.incorrectStyle
                } else {
                  tmpSelectedAnswers[answer_id] = this.state.selectedStyle
                }
                let correct_answer_style = v.id + "_" + v.testrun_id + "_" + v.correct_answer.id;
                tmpSelectedAnswers[correct_answer_style] = this.state.correctStyle;
                let correct_answer = "answer_" + v.correct_answer.id;
                new_results[correct_answer] = <FcOk size={28}/>
              })
              this.setState({results:new_results})
              this.setState({selectedAnswers:tmpSelectedAnswers})
              console.log(tmpSelectedAnswers)
            })
            .catch((error)=>{
              console.log(error);
            });
         }
      }

    render() { 
      if (getLoginStatus()) {
return (
        <div className="container">
        {/* <div className='row'><ShowNavbar name="PATENTD" /></div> */}
        <div className="row">
        <div className='App list-group-item
        justify-content-center align-items-center mx-auto' 
        style={{ "marginTop":"15px", "backgroundColor": "#e3f2fd"}}>
          <h2>
            Twoje odpowiedzi na test 
          </h2>

          </div>
          </div>
          {this.state.questions.map((d,index) => ( 
            <TestRunItem testrun_id={d.testrun_id} id={d.id} question={d.question} index={index} key={d.id} state={this.state} qid={d.qid} answerSelect={this.answerSelect} />
            ))}
          <div className="row border my-2">
          <div className='row'><div className='col'></div><div className='col my-2'>{this.state.resultMessage} {this.state.resultText}</div></div>
          </div>
          <div className='row'>
          <div className='col-sm-1'></div>
            <div className='col d-flex'>
              </div>

              <div className='col d-flex'>
              </div>

                </div>
          <div className='row my-2'>
            <div className='col'>
            <h6 className="card">Copyright(c) 2023, PZSSPATENT</h6></div>
          </div>

      </div>
);
          } else {
            return (
              <div><h5>Nie jesteś zalogowany</h5></div>
            )
          }
};
}

export default withRouter(ShowTest);
