import React from 'react'


function AnswerList(props) {
    return (
        <div className="row" style={{display: "flex", justifyContent: "center"}}>
            <div className='col-sm-1 pt-2' style={{display: "flex"}}><div id={`answer_${props.qid}`}>{props.state.results[`answer_${props.qid}`]}</div></div>
            <div className='col'>
                <button  className="btn text-black m-1" id={`${props.id}_${props.testrun_id}_${props.qid}`}
                style={props.state.selectedAnswers[`${props.id}_${props.testrun_id}_${props.qid}`]} onClick={props.answerSelect}>
                {props.content}
                </button>
            </div>
        </div>
    )
}

export default AnswerList;