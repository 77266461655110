import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { getLoginStatus } from '../components/services/auth-header';
import authHeader from '../components/services/auth-header';
import { format } from 'date-fns'
//import { ErrorResponse } from "@remix-run/router";

function productFullDescription(data) {
  return data.description + " w cenie " + data.price +  " PLN"
}

class PaymentForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: {},
        actionMessage: "",
        noRefundAgreement: false,
        activeSubscription: false,
        products: [],
        selectedProduct:0,
      };
  
      this.selectProduct = this.selectProduct.bind(this);
      this.setNoRefundAgreement = this.setNoRefundAgreement.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

    }
  	componentDidMount() {
      console.log("BACKEND:", process.env.REACT_APP_BACKEND)
      if (getLoginStatus()) {
        axios.get(process.env.REACT_APP_BACKEND + "/getuser", {headers: authHeader()})
        .then((response) => {
        this.setState({user:response.data})
        this.setState({isLoggedIn: true})
        let now = new Date();
        //console.log(now);
        let user_validity = Date.parse(response.data.valid_ts);
        //console.log(user_validity);
        if (user_validity > now) {
          this.setState({activeSubscription: true})

        } 
        console.log("User subscription active?: " + this.state.activeSubscription);
        //console.log(this.state.json_data)
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error.response);
        });
        axios.get(process.env.REACT_APP_BACKEND + "/product_list", {headers: authHeader()})
        .then((response) => {
          this.setState({products:response.data})
          this.setState({selectedProduct:response.data[0].id})
        });
    }
  }
    selectProduct(e) {
      console.log("selectedProduct:",e.target.id)
      this.setState({selectedProduct: e.target.id});
    }
    setNoRefundAgreement(e) {
      this.setState({noRefundAgreement: e.target.checked})
      //console.log("setFirstTime(after): Event:", e.target.checked, "State:",this.state.firstTime )
      // if (!e.target.checked) {
      //   this.setState({firstTime: true});
      // } else {
      //   this.setState({firstTime: false});
      // }
      // console.log("setFirstTime(after): Event:", e.target.checked, "State:",this.state.firstTime )

    }
  
    handleSubmit(event) {
      this.setState({actionMessage: ""})
      if (!this.state.noRefundAgreement) {
        this.setState({actionMessage: "Kupujesz usługę z natychmiastowym dostepem. Musisz wyrazić zgodę na utratę prawa do odstąpienia od umowy."})
      } else {
              //alert('A name was submitted: ' + this.state.email);
      this.setState({actionMessage: "Rozpoczynam transakcję"})    
      //window.location.replace('https://przelewy24.pl');
      const regFormData = new FormData();
      // regFormData.append("user_id", this.state.user.id)
      // regFormData.append("email", this.state.user.email)
      // regFormData.append("product_id", this.state.selectedProduct)
      console.log("handleSubmit(): Selected packet " + this.state.selectedProduct)
       try {
         // make axios post request
         axios.post(process.env.REACT_APP_BACKEND + "/payment", {"user_id": this.state.user.id, "email": this.state.user.email, "product_id": this.state.selectedProduct}, { headers: authHeader()})
         //axios.post(process.env.REACT_APP_BACKEND + "/payment", regFormData, { headers: authHeader()})
		   .then((response) => {
         if (response.status === 200) {
           const j = response.data
      //     console.log(j)
           if (j.error) {
           //console.log(response.data.json)
           this.setState({actionMessage: j.error})
      //     // console.log(response.data)
           }
           if (j.payment_url) {
             //this.setState({actionMessage: j.message})
             window.location.replace(j.payment_url);
           }
      //   }
       } else {
        this.setState({actionMessage: "Bład serwera. Kod błędu: " + response.status})
       }
      })
      } catch(error) {
         console.log(error)
       }
      }
      event.preventDefault();
     }
    render() {
      if (getLoginStatus()) {
        if (!this.state.activeSubscription) {
        return (
            <>
                <div className="row my-2">
                Wybierz rodzaj pakietu jaki chcesz kupić
                </div>
                <div className="row">
                    <div className="col-sm my-1">
                    <Form>
                    <Form.Group className="mb-3" controlId="productBuyForm">
                    {/* <Form.ButtonGroup aria-label="Wybrany pakiet" onChange={this.ButtonGroupProduct} > */}
                    <div onChange={this.selectProduct}>
                      {this.state.products.map(opt => (
                        // <option value={opt.id} key={opt.id}>{opt.name}</option>
                        <Form.Check label={productFullDescription(opt)} name="productId" type="radio" id={opt.id} />
                      ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formRegisterFirsttime">
                        <Form.Check type="checkbox" id="noRefundAgreement" label="Wyrażam zgodę na natychmiastowe spełnienie świadczenia i dostarczenie treści cyfrowej oraz przyjmuję do wiadomości, że wraz z ich dostarczeniem utracę prawo odstąpienia od umowy." onChange={this.setNoRefundAgreement}/>
                    </Form.Group>
                    <Button variant="primary mb-2" type="submit" onClick={this.handleSubmit}>
                        Opłać
                    </Button>
                    </Form>
                    </div>
                    <div className="col-sm my-1" style={{'height': '100px'}}>
                    <div className="alert alert-primary h-100" role="alert"><b>{this.state.actionMessage}</b></div>
                    </div>
                </div>
              </>
          );
          } else {
            return (
              <>
                <div className="row">
                  <div className='col my-2'>
                    <h4>Twoje konto posiada wykupiony pakiet dostępu ważny do: {format(Date.parse(this.state.user.valid_ts), 'yyyy/MM/dd hh:mm')} </h4>
                    </div>
                </div>
              </>
            )
          }
        } else {
          return (
            <>
              <div className="row">
                <div className='col'>
                  <h4>Nie jesteś zalogowany.</h4>
                  </div>
              </div>
            </>
          )

        }
     // return (

    //     <Form>
    //     <Form.Group className="mb-3" controlId="formBasicEmail">
    //       <Form.Label>Email address</Form.Label>
    //       <Form.Control type="email" placeholder="Enter email" />
    //       <Form.Text className="text-muted">
    //         We'll never share your email with anyone else.
    //       </Form.Text>
    //     </Form.Group>
  
    //     <Form.Group className="mb-3" controlId="formBasicPassword">
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control type="password" placeholder="Password" />
    //     </Form.Group>
    //     <Form.Group className="mb-3" controlId="formBasicCheckbox">
    //       <Form.Check type="checkbox" label="Check me out" />
    //     </Form.Group>
    //     <Button variant="primary" type="submit">
    //       Submit
    //     </Button>
    //   </Form>

    //     {/* <form onSubmit={this.handleSubmit}>
    //     <div class="form-group">
    //         <label for="exampleInputEmail1">Email address</label>
    //         <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    //         <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    //     </div>
    //       <label>
    //         Name:
    //         <input type="text" value={this.state.value} onChange={this.handleChange} />
    //       </label>
    //       <input type="submit" value="Submit" />
    //     </form> */}
    //   );
    }
  }
  export default PaymentForm;
