import './App.css';

// import ShowNavbar from './components/NavBar';
import TopBar from './components/index';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import { findAllByTestId } from '@testing-library/react';
import Authenticate from './pages/authenticate';
import Home from './pages';
import About from './pages/logout';
import Logout from './pages/logout';
import TestResults from './pages/results';
import RunTest from './pages/runtest';
import ShowTest from './pages/showtest';
import PaymentForm from './pages/buy';

// function App() {
//   const [questionList, testQuestionList] = useState([{}])
//   useEffect(() => {
//     axios.get('http://10.3.4.21:5000/generate_test/edd59bf8-8bbe-11ed-b7c4-49bf9c5d5141')
//     .then( res => {
//       testQuestionList(res.data)
//     })
//   });
//class App extends React.Component {
  const App = () => {
    //const [isLoggedIn, setisLoggedIn] = useState(false);

  //render() {
  return (
    <>
    <div className='container pt-2'>
    <Router>
      <div className='row h-25 mx-10'>
    <TopBar />
    </div>
    <Routes>
        <Route path='/' element={ <Home />}/>
        <Route path='/testresults' element={<TestResults/>} />
        <Route path='/runtest' element={<RunTest/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/logout' element={<Logout/>} />
        <Route path='/authenticate' element={<Authenticate />} />
        <Route path='/showtest/:tid' element={<ShowTest />} />
        <Route path='/buy' element={<PaymentForm />} />
    </Routes>
    </Router>

    </div>
          <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2023 Copyright: &nbsp;
          <a className='text-reset fw-bold' href='https://pzsspatent.pl/'> 
            PZSSPATENT
          </a>
          &nbsp;|&nbsp;
          <a className='text-reset fw-bold' href={process.env.PUBLIC_URL + "/regulamin.pdf"}> 
            REGULAMIN
            </a>
            &nbsp;|&nbsp;
           <a className='text-reset fw-bold' href={process.env.PUBLIC_URL + "/cennik.pdf"}> 
            CENNIK
            </a>
        </div>
        </>
  );
//}
}

export default App;
