import React from "react";
import { NavLink, NavMenu }
	from "./NavbarElements";
	import Nav from 'react-bootstrap/Nav';
import { useState, useEffect } from "react";
import { getLoginStatus } from "./services/auth-header";
import { LinkContainer } from 'react-router-bootstrap'

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const TopBar = () => {
return (
	<>
	<Navbar bg="dark" variant="dark">
        <Container>
		<LinkContainer to="/">
          <Navbar.Brand href="/">Twoje Konto</Navbar.Brand>
		</LinkContainer>
          <Nav className="me-auto">
		  <LinkContainer to="/testresults">
            <Nav.Link href="/testresults">Wyniki Testów</Nav.Link>
		</LinkContainer>
		<LinkContainer to="/runtest">
		<Nav.Link href="/runtest">Rozpocznij Test</Nav.Link>
		</LinkContainer>
		<LinkContainer to="/logout">
		<Nav.Link href="/logout">Wyloguj</Nav.Link>
		</LinkContainer>
          </Nav>
        </Container>
    </Navbar>
	</>
);
	// } else {
	// 	return (
	// 		<></>
	// 	)
	// }
};

export default TopBar;
