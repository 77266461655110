export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    //console.log(user);
    if (user && user.access_token) {
        //console.log("Token acquired")
      return { Authorization: 'Bearer ' + user.access_token };
    } else {
      return {};
    }
  }

  export function getLoginStatus() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return true
    } else {
      return false
    }
  }
