import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
//import ModalMsg from '../components/services/modal-msg.js';

class Register extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        firstTime: false,
        actionMessage: ""
      };
  
      this.setEmail = this.setEmail.bind(this);
      this.setFistTime = this.setFistTime.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    setEmail(e) {
      //console.log("setEmail:",e.target.value)
      this.setState({email: e.target.value});
    }
    setFistTime(e) {
      this.setState({firstTime: e.target.checked})
      //console.log("setFirstTime(after): Event:", e.target.checked, "State:",this.state.firstTime )
      // if (!e.target.checked) {
      //   this.setState({firstTime: true});
      // } else {
      //   this.setState({firstTime: false});
      // }
      // console.log("setFirstTime(after): Event:", e.target.checked, "State:",this.state.firstTime )

    }
  
    handleSubmit(event) {
      this.setState({actionMessage:""})
      //alert('A name was submitted: ' + this.state.email);
      const regFormData = new FormData();
      regFormData.append("email", this.state.email)
      regFormData.append("first_time", this.state.firstTime)
      //const headers = Object.assign({}, authHeader(), { "Content-Type": "multipart/form-data" })
      //console.log("PostData:")
      //console.log(Array.from(regFormData))
      //if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      try {
        // make axios post request
        axios.post(process.env.REACT_APP_BACKEND + "/newsession", {"email": this.state.email, "first_time": this.state.firstTime}, { "Content-Type": "application/json" })
		  .then((response) => {
        if (response.status === 200) {
          const j = JSON.parse(response.data)
          console.log(j)
          if (j.error) {
          //console.log(response.data.json)
          this.setState({actionMessage: j.error})
          // console.log(response.data)
          }
          if (j.message) {
            this.setState({actionMessage: j.message})
          }
        }
      })
      } catch(error) {
        console.log(error)
      }
    } else {
      this.setState({actionMessage:"Nieprawidłowy adres e-mail"})
    }
       event.preventDefault();
     }
  
    render() {
        return (
            <>
                <div className="row">
                    <h4>Zaloguj się / Zarejestruj</h4>
                </div>
                <div className="row">
                    <div className="col-sm">
                    <Form>
                    <Form.Group className="mb-3" controlId="formRegisterEmail">
                        <Form.Label>Adres e-mail na jaki otrzymasz link do zalogowania się.</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={this.setEmail}/>
                        <Form.Text className="text-muted">
                        Twój adres e-mail będzie używany tylko to logowania
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formRegisterFirsttime">
                        <Form.Check type="checkbox" id="firstTime" defaultChecked={this.state.firstTime} label="Pierwsze logowanie (rejestracja). Zakładając konto akceptujesz regulamin." onChange={this.setFistTime}/>
                    </Form.Group>
                    <Button variant="primary mb-2" type="submit" onClick={this.handleSubmit}>
                        Zaloguj/Zarejestruj
                    </Button>
                    </Form>
                    </div>
                    <div className="col-sm my-2" style={{'height': '100px'}}>
                    <div className="alert alert-primary h-100" role="alert"><b>{this.state.actionMessage}</b></div>
                    </div>
                </div>
              </>
          );
     // return (

    //     <Form>
    //     <Form.Group className="mb-3" controlId="formBasicEmail">
    //       <Form.Label>Email address</Form.Label>
    //       <Form.Control type="email" placeholder="Enter email" />
    //       <Form.Text className="text-muted">
    //         We'll never share your email with anyone else.
    //       </Form.Text>
    //     </Form.Group>
  
    //     <Form.Group className="mb-3" controlId="formBasicPassword">
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control type="password" placeholder="Password" />
    //     </Form.Group>
    //     <Form.Group className="mb-3" controlId="formBasicCheckbox">
    //       <Form.Check type="checkbox" label="Check me out" />
    //     </Form.Group>
    //     <Button variant="primary" type="submit">
    //       Submit
    //     </Button>
    //   </Form>

    //     {/* <form onSubmit={this.handleSubmit}>
    //     <div class="form-group">
    //         <label for="exampleInputEmail1">Email address</label>
    //         <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    //         <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    //     </div>
    //       <label>
    //         Name:
    //         <input type="text" value={this.state.value} onChange={this.handleChange} />
    //       </label>
    //       <input type="submit" value="Submit" />
    //     </form> */}
    //   );
    }
  }
  export default Register;
