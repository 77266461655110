import React from 'react';
import axios from 'axios';
import authHeader from '../components/services/auth-header';
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import Register from "../pages/register";
import { getLoginStatus } from '../components/services/auth-header';
class Home extends React.Component {

	constructor(props) {
		super(props)
		this.state = { 
			user: {},
			isLoggedIn: false,
			isActive: false,
			accountMsg: "",
			paymentButton: "",
		}
	}
	componentDidMount() {
		console.log("BACKEND:", process.env.REACT_APP_BACKEND)
		if (getLoginStatus()) {
			axios.get(process.env.REACT_APP_BACKEND + "/getuser", {headers: authHeader()})
			.then((response) => {
			this.setState({user:response.data})
			this.setState({isLoggedIn: true})
			let now = new Date();
			//console.log(now);
			let user_validity = Date.parse(response.data.valid_ts);
			//console.log(user_validity);
			if (user_validity > now) {
				this.setState({accountMsg: <div className="text-success">Twoje konto jest <b>opłacone</b> do</div>})
			} else {
				this.setState({accountMsg: <div className="text-danger">Twoje konto jest <b>niaktywne</b> od</div>})
				this.setState({paymentButton: <a href="/buy"  className="btn btn-primary btn-sm">Kup dostęp</a>})
			}
			//console.log(this.state.json_data)
			})
			.catch(error => {
				// Handle error.
				console.log('An error occurred:', error.response);
			});
	}
}
	render () {
		const isLoggedIn = this.state.isLoggedIn;
		if(isLoggedIn) {
			return (
				<>
					<div className='row'>
						<div className='col'>
							<div className="h5">
								Konto: {this.state.user.email}
							</div>
						</div>
						<div className='col'>
							<div>
								{this.state.accountMsg}
								<SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{this.state.user.valid_ts}</SimpleDateTime>
							</div>
						</div> 
						<div className='col my-2'>
							{this.state.paymentButton}
						</div>
								
					</div>
                <div className='row'>
                    <div className='col my-2'>
                        <div></div>
                    </div>
                </div>
				</>
				)
		} else {
			return (
				<div className='container pt-2'>
					<div className='row'>
						<div className='col-sm'>
							<Register />
							</div>
					</div>
					<div className='row'>
						<div className='col-sm my-2'>
						{/* <h5>Przygotowujesz się do państwowego egzaminu na patent strzelecki PZSS? <br />Chcesz sobie ułatwić naukę? <br />
						Dobrze trafiłeś. Znajdziesz tu bazę pytań wraz z odpowiedziami, która obowiązuje na takim
						egzaminie.<br /> */}
						{/* <img className="img-fluid rounded" src={process.env.PUBLIC_URL + "/img/pzss.jpg"}></img> */}

						<p>
						<table class="table">
							<tr>
								<td width="40%">		<h6>Przygotowujesz się do państwowego egzaminu na patent strzelecki PZSS? <br />Chcesz sobie ułatwić naukę? <br />
						Dobrze trafiłeś. Znajdziesz tu bazę pytań wraz z odpowiedziami, która obowiązuje na takim
						egzaminie.<br /></h6>
						<img className="img-fluid rounded" width="80%" src={process.env.PUBLIC_URL + "/img/pzss.jpg"}></img>
						</td>
								<td>
								<img className="img-fluid rounded" src={process.env.PUBLIC_URL + "/img/pupils.jpg"}></img>
								</td>
							</tr>
						</table>
						{/* <img className="img-fluid rounded" src={process.env.PUBLIC_URL + "/img/pzss.jpg"}></img> */}

						</p>
						Zapraszamy do wykupienia pakietu z dostępem do testów na 30 dni lub na 90 dni (ceny zgodnie z cennikiem).
						</div>
                        <div>
                        <p>
						<b>Niniejsza strona nie jest własnością  Polskiego Związku Strzelectwa Sportowego.
                            Kontakt wsparcie@pzsspatent.pl tel 601 080 345</b>
                        </p>
                    </div>
						{/* <div className='col my-2'>
							<img className="img-fluid rounded" src={process.env.PUBLIC_URL + "/img/pupils.jpg"}></img>
						</div> */}
					</div>
				</div>
				)
		}

	};
}


export default Home;
