import React from 'react'
import AnswerList from './AnswerList';
import { FcCancel, FcOk, FcApproval } from "react-icons/fc";

function TestRunItem(props) {
    return (
      <div className='row'>
      <div className='col-sm-1 d-flex align-items-center'>
        <h5>{props.index+1}</h5>
      </div>
      <div className='col'>
        <div className="row my-2" id={`${props.id}_${props.testrun_id}`}>
          <h5 className="card" style={{"backgroundColor": "#a7bed3"}}>
         <div className="my-2">{props.question.content}</div>
        </h5>
        </div>
        </div>
        {props.question.answers.map((a,qid) => (
          <AnswerList qid={a.id} content={a.content} id={props.id} testrun_id={props.testrun_id} answerSelect={props.answerSelect} index={qid} state={props.state} key={`answer_${a.id}`}/>
        ))}
      </div>
    )
}

export default TestRunItem;
